// gatsby-browser.js
export const onRouteUpdate = ({ location }) => {
    // 1. Capture UTM from URL and store in session
    const searchParams = new URLSearchParams(location.search);
    const utmParams = [];
    for (const [key, value] of searchParams.entries()) {
      if (key.startsWith('utm_')) {
        utmParams.push(`${key}=${value}`);
      }
    }
  
    // Store UTM params in sessionStorage if available
    if (utmParams.length > 0) {
      sessionStorage.setItem('utmParams', utmParams.join('&'));
    }
  
    // 2. Add UTM to sensei3d links from sessionStorage
    const updateSenseiLinks = () => {
      const storedUtm = sessionStorage.getItem('utmParams');
      if (storedUtm) {
        const links = document.querySelectorAll('a[href*="sensei3d"]');
        links.forEach(link => {
          const currentUrl = link.getAttribute('href');
          if (!currentUrl.includes(storedUtm)) {
            const newUrl = currentUrl.includes('?')
              ? `${currentUrl}&${storedUtm}`
              : `${currentUrl}?${storedUtm}`;
            link.setAttribute('href', newUrl);
          }
        });
      }
    };
  
    // Run update on page load and route change
    if (document.readyState === 'complete') {
      updateSenseiLinks();
    } else {
      window.addEventListener('load', updateSenseiLinks);
    }
  };
  